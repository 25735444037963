<!-- 商品编辑 -->
<template>
  <div class="goods_edit">
    <div class="goods_edit_body">
      <!-- 商品信息 -->
      <div class="goods_div">
        <!-- <div class="goods_title title">
          商品信息
          <div class="dashed"></div>
        </div> -->
        <DetailTitle titleName="基础信息" />
        <div class="goods_info">
          <el-row :gutter="20">
            <el-col :span="4">
              <div>
                <span class="goods_label">配件编码：</span>
                <span class="goods_content">{{ dataObj.code }}</span>
              </div>
            </el-col>
            <el-col :span="4">
              <div>
                <span class="goods_label">配件名称：</span>
                <span class="goods_content">{{ dataObj.name }}</span>
              </div>
            </el-col>
            <el-col :span="4">
              <div>
                <span class="goods_label">配件品牌：</span>
                <span class="goods_content">{{ dataObj.brand }}</span>
              </div>
            </el-col>
            <el-col :span="4">
              <div>
                <span class="goods_label">性质：</span>
                <span class="goods_content">{{ dataObj.property }}</span>
              </div>
            </el-col>
            <el-col :span="4">
              <div>
                <span class="goods_label">单位：</span>
                <span class="goods_content">{{ dataObj.unit }}</span>
              </div>
            </el-col>
            <el-col :span="4">
              <div>
                <span class="goods_label">车型：</span>
                <span class="goods_content">{{ dataObj.vehModel }}</span>
              </div>
            </el-col>

            <el-col :span="4">
              <div>
                <span class="goods_label">汽车品牌：</span>
                <span class="goods_content">{{ dataObj.vehBrand }}</span>
              </div>
            </el-col>
            <el-col :span="4">
              <div>
                <span class="goods_label">产地：</span>
                <span class="goods_content">{{ dataObj.productionPlace }}</span>
              </div>
            </el-col>
            <el-col :span="4">
              <div>
                <span class="goods_label">规格：</span>
                <span class="goods_content">{{ dataObj.model }}</span>
              </div>
            </el-col>

            <el-col :span="4">
              <div>
                <span class="goods_label">生产码：</span>
                <span class="goods_content">{{ dataObj.productionCode }}</span>
              </div>
            </el-col>
            <el-col :span="4">
              <div>
                <span class="goods_label">原厂名称：</span>
                <span class="goods_content">{{ dataObj.oeName }}</span>
              </div>
            </el-col>
            <el-col :span="4">
              <div>
                <span class="goods_label">配件类别：</span>
                <span class="goods_content">{{ dataObj.partType }}</span>
              </div>
            </el-col>
            <el-col :span="4">
              <div>
                <span class="goods_label">配件分类：</span>
                <span class="goods_content">{{ dataObj.category }}</span>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <!-- 库存配置 -->
      <div class="goods_div">
        <div class="goods_info">
          <DetailTitle
            class="mb-8"
            titleName="库存设置"
            des="在可订货数量的基础上设置展示给客户看的库存信息，未包含在库存区间内的将展示真实的库存）"
          />
          <div class="flex space-x-24 mb-8">
            <div v-if="dataObj.qty">
              <span class="goods_label">库存数量：</span>
              <span class="goods_content">{{ dataObj.qty }}</span>
            </div>
            <div v-if="dataObj.lockedQty">
              <span class="goods_label">锁定数量：</span>
              <span class="goods_content">{{ dataObj.lockedQty }}</span>
            </div>
            <div v-if="dataObj.orderQty">
              <span class="goods_label">可订货数量：</span>
              <span class="goods_content">{{ dataObj.orderQty }}</span>
            </div>
          </div>
          <!-- <div class="mb-8 px-16">
            <span class="mr-8">库存展示维度</span>
            <el-select
              v-model="stockShowMode"
              placeholder="请选择"
              size="small"
            >
              <el-option
                v-for="item in stockOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <span class="ml-8"
              >若按配件+库存分组设置，需要先创建库存分组
              <span
                class=" text-primary cursor-pointer"
                @click="$router.push('/goodsManage/stockGroup')"
                >去设置 ></span
              >
            </span>
          </div> -->
          <div class="stock-container px-16">
            <div style="max-height: 200px; overflow: auto">
              <div
                v-for="(item, index) in stockSetting.details"
                :key="index"
                style="
								display: flex;
								align-items: center;
								margin-bottom: 8px;
							"
              >
                <el-tooltip
                  :enterable="false"
                  effect="dark"
                  content="删除"
                  placement="top"
                >
                  <i
                    class="el-icon-delete del_logo"
                    @click="clearStockItem(index)"
                  ></i>
                </el-tooltip>
                <div style="margin-right: 8px">可订货库存数量范围在</div>
                <el-input
                  v-model="item.start"
                  type="number"
                  size="small"
                  placeholder="-"
                  style="flex: none; width: 90px"
                  @change="onNumberInput('start', item)"
                />
                <div style="margin: 0 8px">至</div>
                <el-input
                  v-model="item.end"
                  type="number"
                  size="small"
                  placeholder="-"
                  style="flex: none; width: 90px"
                  @change="onNumberInput('end', item)"
                />
                <div style="margin: 0 8px">时</div>
                <div
                  style="
									display: flex;
									align-items: center;
									margin-left: 5px;
								"
                >
                  <el-select v-model="item.type" size="small" style="">
                    <el-option
                      v-for="(type, index) in STOCK_TYPES"
                      :key="index"
                      :label="type.name"
                      :value="type.code"
                    />
                  </el-select>
                  <el-input
                    v-model="item.value"
                    v-show="item.type === 'specified'"
                    size="small"
                    maxLength="10"
                    style="flex: none; width: 80px"
                    @change="onNumberInput('value', item)"
                  />
                </div>
              </div>
            </div>
            <el-button type="text" @click="addStockItem"
              >添加库存区间</el-button
            >
          </div>
          <div class="mb-8 px-16 mt-8">
            <span class="mr-8">可订货库存数量不足起订量时</span>
            <el-select
              v-model="stockSetting.lessMinBuyQtyType"
              placeholder="请选择"
              size="small"
            >
              <el-option
                v-for="item in stockLackOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <el-input
              v-model="stockSetting.value"
              v-if="stockSetting.lessMinBuyQtyType === 'specified'"
              type="text"
              size="small"
              maxLength="10"
              style="flex: none; width: 80px;margin-left: 8px;"
            />
          </div>
        </div>
      </div>
      <!-- 价格设置 -->
      <div class="goods_div">
        <div class="goods_info">
          <DetailTitle class="mb-8" titleName="价格展示设置" />
          <div class="flex space-x-24 mb-8">
            <div v-for="(key, value) in dataObj.prices">
              <span class="goods_label">{{ value }}：</span>
              <span class="goods_content">{{ key }}元</span>
            </div>
          </div>
          <div class="mb-8 px-16">
            <span class="mr-8">指定公开展示价格类型</span>
            <el-select
              v-model="priceSetting.defaultPriceType"
              placeholder="请选择"
              size="small"
            >
              <el-option
                v-for="item in priceOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="stock-container">
            <div style="max-height: 200px; overflow: auto">
              <div
                v-for="(item, index) in priceSetting.details"
                :key="index"
                style="
								display: flex;
								align-items: center;
								margin-bottom: 8px;
							"
              >
                <el-tooltip
                  :enterable="false"
                  effect="dark"
                  content="删除"
                  placement="top"
                >
                  <i
                    class="el-icon-delete del_logo"
                    @click="clearPriceItem(index)"
                  ></i>
                </el-tooltip>
                <div style="margin-right: 8px">展示价格范围在</div>
                <el-input
                  v-model="item.start"
                  type="number"
                  size="small"
                  placeholder="-"
                  style="flex: none; width: 90px"
                  @change="onNumberInput('start', item)"
                />
                <div style="margin: 0 8px">至</div>
                <el-input
                  v-model="item.end"
                  type="number"
                  size="small"
                  placeholder="-"
                  style="flex: none; width: 90px"
                  @change="onNumberInput('end', item)"
                />
                <div style="margin: 0 8px">时</div>
                <div
                  style="
									display: flex;
									align-items: center;
									margin-left: 5px;
								"
                >
                  <el-select v-model="item.type" size="small" style="">
                    <el-option
                      v-for="(type, index) in PRICE_TYPES"
                      :key="index"
                      :label="type.name"
                      :value="type.code"
                    />
                  </el-select>
                  <el-input
                    v-model="item.value"
                    v-show="item.type === 'specified'"
                    size="small"
                    maxLength="10"
                    style="flex: none; width: 80px"
                    @change="onNumberInput('value', item)"
                  />
                </div>
              </div>
            </div>
            <el-button type="text" @click="addPriceItem"
              >添加价格范围</el-button
            >
          </div>
          <div class="mb-8 px-16 mt-8">
            <span class="mr-8">划线价</span>
            <el-select
              v-model="priceSetting.linePriceType"
              placeholder="请选择"
              size="small"
              @change="linePriceChange"
            >
              <el-option
                v-for="item in linePriceTypeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <el-select
              v-model="priceSetting.value"
              size="small"
              style="margin-left: 8px;"
              v-if="priceSetting.linePriceType === 'price'"
            >
              <el-option
                v-for="(item, index) in priceOptions"
                :key="index"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
            <el-input
              v-model="priceSetting.value"
              v-if="priceSetting.linePriceType === 'specified'"
              type="text"
              size="small"
              maxLength="10"
              style="flex: none; width: 80px;margin-left: 8px;"
            />
          </div>
        </div>
      </div>
      <!-- 其他 -->
      <div class="goods_div">
        <!-- <div class="goods_title">其他</div> -->
        <DetailTitle titleName="其他" />
        <div class="goods_info" style="padding-bottom:0;">
          <div class="flex_box " style="align-items: center;">
            <span class="label">卖点</span>
            <el-input
              style="width:70rem;"
              type="text"
              placeholder="请输入内容"
              v-model="dataObj.description"
              maxlength="50"
              show-word-limit
            >
            </el-input>
          </div>
          <div class="flex_box ">
            <span class="label">图片</span>
            <div>
              <el-upload
                ref="elUpload"
                :action="imgAction"
                accept="image/png, image/jpeg"
                list-type="picture-card"
                :limit="5"
                :file-list="fileData"
                multiple
                :on-exceed="handleExceed"
                :before-upload="beforeUpload"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove"
                :http-request="uploadReqFn"
              >
                <i slot="default" class="el-icon-plus"></i>
                <div style="display:flex" slot="tip">
                  <template v-for="(item, index) in fileData">
                    <div class="img_title_btn" v-if="index === 0" :key="index">
                      <span>主图</span>
                    </div>
                    <div class="img_title_btn" v-else :key="item">
                      <a @click="setImgMain(index)">设为主图</a>
                    </div>
                  </template>
                </div>
              </el-upload>
              <el-dialog title="图片" :visible.sync="dialogVisible">
                <img width="100%" :src="dialogImageUrl" alt="" />
              </el-dialog>
            </div>
          </div>
          <div class="flex_box ">
            <span class="label">详情</span>
            <Editor :content="dataObj.remarks" :catchData="changeEditor" />
          </div>
        </div>
      </div>
    </div>
    <div class="goods_edit_footer">
      <el-button size="small" @click="handleCancel">取消</el-button>
      <el-button type="primary" size="small" @click="onSave">保存</el-button>
    </div>
  </div>
</template>

<script>
import Editor from "./components/wangEditor.vue";
import DetailTitle from "@/components/manageCom/DetailTitle.vue";
import { base64toFile } from "@/utils/tool.js";
import { uploadImgFile } from "@/api/commonService";
import { GoodsEditApi } from "./services";
const goodsEditApi = new GoodsEditApi();
import { SettingApi } from "../../setting/basicSetting/services";
const settingApi = new SettingApi();
const STOCK_TYPES = [
  { code: "original", name: "展示真实库存" },
  { code: "specified", name: "展示指定内容" },
  { code: "none", name: "展示无货" },
];
const PRICE_TYPES = [
  { code: "original", name: "展示真实价格" },
  { code: "specified", name: "展示指定内容" },
  { code: "none", name: "展示议价" },
];
export default {
  name: "goods_edit",
  components: {
    Editor,
    DetailTitle,
  },
  data() {
    return {
      PRICE_TYPES,
      imgAction: "#", //图片上传接口
      goodsType: "零配件", //零配件 / 服务项目 / 项目套餐
      dataObj: {
        linePriceType: "max", // 划线价取值方式 max-划线价取最高价 custom-划线价自定义
        description: "", // 卖点
      },
      stockShowMode: "part",
      imageShowMode: "erp",
      stockSetting: {
        partNameRule: 0,
        details: [],
        allowZeroStock: true,
        checkStock: true, //检查库存
        autoConfirmOrder: false, //自动确认订单
        autoReceiveOrder: 15,
        stockShowMode: 0, //库存展示纬度
        allowZeroPrice: false, // 是否展示零价格配件，true: 展示;false: 不展示
        autoConfirmOfflinePay: false, // 订单线下支付财务审核方式，true: 自动;false: 手动
        showLinePrice: false, // 是否展示划线价
      },
      priceSetting: {
        details: [],
      },
      autoAuditCoop: null, //自动审核往来单位(客户)申请
      premiumRates: {}, //加价比例
      paymentTypeList: [],
      invoiceTypeList: [],
      priceTypeList: [],
      showFirstTab: false,
      tabs: [],
      active: 0,
      STOCK_TYPES,
      isErpUser: false, //是否是erp用户
      stockOptions: [
        {
          label: "按配件",
          value: "part",
        },
        {
          label: "按配件+库存分组",
          value: "partAndGroup",
        },
        {
          label: "按配件+仓库",
          value: "partAndWarehouse",
        },
      ],
      stockLackOptions: [
        {
          label: "展示真实库存/真实值",
          value: "original",
        },
        {
          label: "指定值",
          value: "specified",
        },
        {
          label: "展示为无货",
          value: "none",
        },
      ],
      linePriceTypeOptions: [
        {
          label: "最高价",
          value: "max",
        },
        {
          label: "指定价格类型",
          value: "price",
        },
        {
          label: "指定值",
          value: "specified",
        },
        {
          label: "不展示",
          value: "none",
        },
      ],
      imageShowModeOptions: [
        {
          label: "展示erp中图片",
          value: "erp",
        },
        {
          label: "展示平台上传的图片",
          value: "plat",
        },
        {
          label: "优先展示ERP",
          value: "firstErp",
        },
        {
          label: "优先展示平台",
          value: "firstPlat",
        },
        {
          label: "不展示",
          value: "none",
        },
      ],
      priceOptions: [
        {
          label: "平台价",
          value: "platform",
        },
        {
          label: "零售价",
          value: "retail",
        },
        {
          label: "批发价",
          value: "p",
        },
        {
          label: "批发价一",
          value: "p1",
        },
        {
          label: "批发价二",
          value: "p2",
        },
        {
          label: "批发价三",
          value: "p3",
        },
        {
          label: "批发价四",
          value: "p4",
        },
      ],
      priceDetails: [],
      fileData: [],
      limitNum: 5,
      picturesLength: 0,
      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,

      STOCK_TYPES: STOCK_TYPES,
      type: null,
    };
  },
  computed: {
    isM8User() {
      //erpType  1:m8用户 2:erp用户
      return this.$store.state.profile.erpType === 1;
    },
    stockShowModeVisible() {
      let visible = true;
      const { hasYP, hasYX } = this.$store.state.base.erp;
      if (hasYP || hasYX) {
        visible = false;
      }
      return visible;
    },
  },
  mounted() {
    window.z = this;
    this.type = this.$route.query.type;
    if (this.type == 0) {
      this.goodsType = "零配件";
    } else if (this.type == 1) {
      this.goodsType = "服务项目";
    } else if (this.type == 2) {
      this.goodsType = "项目套餐";
    }
    this.initData();
  },
  methods: {
    initData() {
      this.fileData = [];
      goodsEditApi
        .getGoodsDetail({
          id: this.$route.query.id,
          type: this.type,
        })
        .then((res) => {
          this.dataObj = { ...res };
          this.dataObj.linePriceType = res.linePriceType || "max";
          if (res.images && res.images.length) {
            const urls = res.images || [];
            for (let url of urls) {
              this.fileData.push({
                name: url,
                url: url,
                saveUrl: url,
              });
            }
          }
          this.picturesLength = this.fileData.length;
          this.stockSetting = {
            ...res.stockSetting,
            details:
              res.stockSetting.details && res.stockSetting.details.length
                ? res.stockSetting.details.map((item) => ({ ...item }))
                : [
                    {
                      start: "0",
                      end: "",
                      value: "",
                      type: "original",
                    },
                  ],
          };
          this.priceSetting = {
            ...res.priceSetting,
            details:
              res.priceSetting.details && res.priceSetting.details.length
                ? res.priceSetting.details.map((item) => ({ ...item }))
                : [
                    {
                      start: "0",
                      end: "",
                      value: "",
                      type: "original",
                    },
                  ],
          };
          this.autoAuditCoop = res?.autoAuditCoop;
          this.stockShowMode = res.stockShowMode;
          this.imageShowMode = res.imageShowMode;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    onNumberInput(type, item, index) {
      var _this = this;
      switch (type) {
        case "start":
          if (item.start && Number(item.start || 0) < 0) {
            item.start = 0;
          }
          break;
        case "endQty":
          if (
            item.endQty &&
            Number(item.endQty || 0) < Number(item.start || 0)
          ) {
            item.endQty = item.start ? Number(item.start) + 1 : 0;
          }
          break;
        case "upperLimitQty":
          if (
            item.upperLimitQty &&
            Number(item.upperLimitQty || 0) < Number(item.start || 0)
          ) {
            item.upperLimitQty = item.start || 0;
          }
          if (
            item.upperLimitQty &&
            Number(item.upperLimitQty || 0) > Number(item.endQty)
          ) {
            item.upperLimitQty =
              Number(item.endQty) > 0 ? Number(item.endQty) - 1 : 0;
          }
          _this.$set(
            _this.stockSetting.details[index],
            "upperLimitQty",
            item.upperLimitQty
          );

          break;
      }
      _this.$forceUpdate();
    },
    clearStockItem(itemIndex) {
      if (this.stockSetting.details && this.stockSetting.details.length <= 0) {
        this.$alert("不能删除", "提示", {
          confirmButtonText: "确定",
        });
      } else {
        this.stockSetting.details = this.stockSetting.details.filter(
          (item, index) => itemIndex !== index
        );
      }
    },
    addStockItem() {
      if (this.stockSetting.details.length >= 50) {
        this.$alert("最多只能添加50条", "提示", {
          confirmButtonText: "确定",
        });
      } else {
        //   先判断前面输入的数据是否有交叉，没有交叉再新增一条
        let isCross = this.checkIsCross();
        if (!isCross) {
          this.stockSetting.details.push({
            start: "",
            endQty: "",
            upperLimitQty: "",
            type: 0,
          });
        }
      }
    },
    checkIsCross() {
      if (this.stockSetting.details && this.stockSetting.details.length > 0) {
        //循环判断是否有不符合规则的输入数据
        for (let item of this.stockSetting.details) {
          if (!item.start && item.start != undefined && !item.endQty) {
            this.$alert("库存区间值不能全部为空", "提示", {
              confirmButtonText: "确定",
            });
            return true;
          } else if (
            // item.start &&
            // item.endQty &&
            Number(item.start) >= Number(item.endQty)
          ) {
            this.$alert("库存区间开始值不能大于等于结束值", "提示", {
              confirmButtonText: "确定",
            });
            return true;
          } else if (item.type === 5) {
            if (!item.upperLimitQty) {
              this.$alert("库存显示上限不能为空", "提示", {
                confirmButtonText: "确定",
              });
              return true;
            }
          }
        }
        return false;
      } else {
        return false;
      }
    },
    changeEditor(data) {
      this.dataObj.remarks = data;
    },
    /* 图片上传  */
    handlePictureCardPreview(file) {
      //点击放大镜按钮
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    clearFiles() {
      this.$message.warning("图片上传失败");
      const list = JSON.parse(JSON.stringify(this.fileData));
      this.$refs.elUpload.clearFiles();
      this.fileData = [...list];
    },
    uploadReqFn(option) {
      const file = option.file;
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      uploadImgFile({
        file,
      })
        .then((res) => {
          if (res && res.length) {
            // 处理图片回显
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = (e) => {
              this.fileData.push({
                name: file.name,
                uid: file.uid,
                url: e.target.result,
                saveUrl: res[0],
              });
              option.readerUrl = e.target.result;
            };
            fileReader.onerror = () => {
              this.clearFiles();
            };
          } else {
            this.clearFiles();
          }
          loading.close();
        })
        .catch(() => {
          this.clearFiles();
          loading.close();
        });
    },
    setImgMain(index) {
      const fileData = this.fileData;
      const arr = fileData.splice(index, 1);
      fileData.unshift(...arr);
    },
    beforeUpload(file) {
      //上传之前
      const isJPG = file.type === "image/jpeg";
      const isPNG = file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;
      //加上上传数量
      this.picturesLength += 1;
      if (!isJPG && !isPNG) {
        this.$message.error("图片只能是 JPG/PNG 格式!");
        return false;
      }
      if (!isLt2M) {
        this.$message.error("图片大小不能超过 2MB!");
        return false;
      }
    },
    handleExceed(files, fileList) {
      // this.$message.error("图片已超出最大个数限制!");
      this.$message.warning(
        `当前限制选择 ${this.limitNum} 个文件，本次选择了 ${
          files.length
        } 个文件，共选择了 ${files.length + fileList.length} 个文件`
      );
    },
    handleRemove(file) {
      const fileData = this.fileData;
      const findIndex = fileData.findIndex((item) => {
        return item.uid === file.uid || item.saveUrl === file.saveUrl;
      });
      if (findIndex !== -1) {
        fileData.splice(findIndex, 1);
      }
    },
    // 取消编辑
    handleCancel() {
      this.$router.go(-1);
    },
    async onSave() {
      //保存
      if (
        this.stockSetting &&
        this.stockSetting.details &&
        this.stockSetting.details.length > 0
      ) {
        for (let item of this.stockSetting.details) {
          item.start = item.start || null;
          item.endQty = item.endQty || null;
          item.upperLimitQty = item.upperLimitQty || null;
        }
      }
      if (
        this.stockSetting &&
        this.stockSetting.details &&
        (this.stockSetting.details.length < 0 ||
          this.stockSetting.details.length > 50)
      ) {
        this.$alert("库存区间设置条数需大于0小于等于50条", "提示", {
          confirmButtonText: "确定",
        });
      } else {
        const loading = this.$loading({
          lock: true,
          text: "Loading",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        let _this = this;
        const { stockSetting, priceSetting } = this;
        stockSetting.details = stockSetting.details.map((item) => {
          if (item.start == "") {
            delete item.start;
          }
          if (item.end == "") {
            delete item.end;
          }
          return item;
        });
        priceSetting.details = priceSetting.details.map((item) => {
          if (item.start == "") {
            delete item.start;
          }
          if (item.end == "") {
            delete item.end;
          }
          return item;
        });
        console.log(this.fileData);
        let params = {
          ...this.dataObj,
          priceSetting: this.priceSetting,
          stockSetting: this.stockSetting,
          images: this.fileData.map((item) => item.saveUrl),
        };
        const remarks = params.remarks || "";
        const re = /"data:image.*?"/g;
        const matchArr = remarks.match(re);
        // 处理base64图片
        if (matchArr) {
          const files = [];
          matchArr.forEach((matchStr) => {
            files.push(
              base64toFile(matchStr.substring(1, matchStr.length - 1))
            );
          });
          const fileSizeTotal = files.reduce((total, file) => {
            return total + file.size;
          }, 0);
          if (fileSizeTotal / 1024 / 1024 > 30) {
            loading.close();
            this.$message.warning("上传图片大于30M，请整理后重新上传！");
            return false;
          }
          const res = await uploadImgFile({ file: files });
          if (res && res.length) {
            let index = 0;
            params.priceSetting.remarks = remarks.replace(
              re,
              () => `"${res[index++]}"`
            );
            goodsEditApi
              .update(params)
              .then(() => {
                _this.$message.success("保存成功");
                loading.close();
              })
              .catch(() => loading.close());
          } else {
            loading.close();
          }
        } else {
          goodsEditApi
            .update(params)
            .then(() => {
              _this.$message.success("保存成功");
              loading.close();
            })
            .catch(() => loading.close());
        }
      }
    },
    toStockGroup() {
      this.$router.push("/goodsManage/stockGroup");
    },
    toNumberFn(item, key) {
      var priceRate = Math.round(parseFloat(item[key] ? item[key] : 0) * 100);
      if (item[key].match(/\d+(\.\d{0,2})?/)) {
        item[key] = priceRate + "%";
      } else {
        item[key] = "0%";
      }
      this.$forceUpdate();
    },
    getpaymentTypeList() {
      let params = {
        type: "paymentType",
      };
      settingApi
        .getDictsByType(params)
        .then((res) => {
          this.paymentTypeList = res;
          if (!this.stockSetting.customerSetting.paymentType) {
            this.stockSetting.customerSetting.paymentType = this.paymentTypeList[0].Code;
          }
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    getinvoiceTypeList() {
      let params = {
        type: "invoiceType",
      };
      settingApi
        .getDictsByType(params)
        .then((res) => {
          this.invoiceTypeList = res;
          if (!this.stockSetting.customerSetting.invoiceType) {
            this.stockSetting.customerSetting.invoiceType = this.invoiceTypeList[0].Code;
          }
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    getpriceTypeList() {
      let params = {
        type: "priceType",
      };
      settingApi
        .getDictsByType(params)
        .then((res) => {
          this.priceTypeList = res;
          if (!this.stockSetting.customerSetting.priceType) {
            this.stockSetting.customerSetting.priceType = this.priceTypeList[0].Code;
          }
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    toggle(tab) {
      //切换
      if (this.active != tab) {
        this.active = tab;
        this.showFirstTab = !this.showFirstTab;
      }
    },
    refrensh() {
      this.$forceUpdate();
    },
    addSettingList() {
      if (this.stockSetting.details.length >= 20) {
        this.$alert("最多只能添加20条", "提示", {
          confirmButtonText: "确定",
        });
      } else {
        //   先判断前面输入的数据是否有交叉，没有交叉再新增一条
        let isCross = this.checkIsCross();
        if (!isCross) {
          this.stockSetting.details.push({
            stockRangeStart: "",
            stockRangeEnd: "",
            upperLimit: "",
          });
        }
      }
    },
    deleteThisItem(index) {
      this.stockSetting.details.splice(index, 1);
    },

    checkIsCross(type) {
      let arr = [];
      const title = type === "price" ? "价格范围" : "库存区间";
      if (type === "price") {
        arr = [...this.priceSetting.details];
      } else {
        arr = [...this.stockSetting.details];
      }
      if (arr && arr.length > 0) {
        //循环判断是否有不符合规则的输入数据
        for (let item of arr) {
          if (!item.start && !item.end) {
            this.$alert(`${title}库存区间值不能全部为空`, "提示", {
              confirmButtonText: "确定",
            });
            return true;
          } else if (
            item.start &&
            item.end &&
            Number(item.start) >= Number(item.end)
          ) {
            this.$alert(`${title}开始值不能大于等于结束值`, "提示", {
              confirmButtonText: "确定",
            });
            return true;
          } else if (item.type === 5) {
            if (!item.value) {
              this.$alert(`${title}显示上限不能为空`, "提示", {
                confirmButtonText: "确定",
              });
              return true;
            }
          }
        }
        return false;
      } else {
        return false;
      }
    },
    toFloatNumberFn(numObj) {
      //百分比转为小数
      var priceObj = {};
      for (let k in numObj) {
        let item = numObj[k].substr(0, numObj[k].length - 1);
        if (item.length == 1) {
          item = parseFloat("0.0" + item) + "";
        } else if (item.length == 2) {
          item = "0." + item;
        } else if (item.length > 2) {
          var intNum = item.substr(0, item.length - 2); //整数
          var floatNum = item.substr(item.length - 2); //小数
          item = intNum + "." + floatNum;
        }
        priceObj[k] = item;
      }
      return priceObj;
    },
    save() {
      const {
        autoAuditCoop,
        stockShowMode,
        imageShowMode,
        stockSetting,
        priceSetting,
      } = this;
      stockSetting.details = stockSetting.details.map((item) => {
        if (item.start == "") {
          delete item.start;
        }
        if (item.end == "") {
          delete item.end;
        }
        return item;
      });
      priceSetting.details = priceSetting.details.map((item) => {
        if (item.start == "") {
          delete item.start;
        }
        if (item.end == "") {
          delete item.end;
        }
        return item;
      });
      settingApi
        .saveSetting({
          autoAuditCoop,
          stockShowMode,
          imageShowMode,
          stockSetting,
          priceSetting,
        })
        .then(() => {
          this.$message.success("修改设置成功!");
          this.initData();
        });
    },
    saveOrigin() {
      let isCross = false;
      isCross = this.checkIsCross();
      if (!isCross) {
        //for循环把参数变成int类型
        if (this.stockSetting.details && this.stockSetting.details.length > 0) {
          for (let item of this.stockSetting.details) {
            item.start = item.start || null;
            item.end = item.end || null;
            item.value = item.value || null;
          }
        }
        if (
          this.stockSetting.details &&
          (this.stockSetting.details.length <= 0 ||
            this.stockSetting.details.length > 50)
        ) {
          this.$alert("库存区间设置条数需大于0小于等于50条", "提示", {
            confirmButtonText: "确定",
          });
        } else {
          var premiumRates = { ...this.premiumRates };
          var priceObj = this.toFloatNumberFn(premiumRates);
          this.stockSetting.premiumRates = Object.assign({}, priceObj);

          if (this.stockSetting.customerSetting.autoAudit) {
            this.stockSetting.customerSetting.discount =
              this.stockSetting.customerSetting.discount !== ""
                ? this.stockSetting.customerSetting.discount
                : null;
          }
          let params = {
            ...this.stockSetting,
            autoReceiveOrder: Number(this.stockSetting.autoReceiveOrder),
          };
          settingApi
            .saveSetting(params)
            .then(() => {
              this.$message({
                message: "操作成功！",
                type: "success",
              });
              this.initData();
            })
            .catch((error) => {
              this.$message.error(error);
            });
        }
      }
    },
    addStockItem() {
      if (this.stockSetting.details.length >= 50) {
        this.$alert("最多只能添加50条", "提示", {
          confirmButtonText: "确定",
        });
      } else {
        //   先判断前面输入的数据是否有交叉，没有交叉再新增一条
        let isCross = this.checkIsCross();
        if (!isCross) {
          this.stockSetting.details.push({
            start: "0",
            end: "",
            value: "无货",
            type: "original",
          });
        }
      }
    },
    linePriceChange() {
      this.priceSetting.value = "";
    },
    addPriceItem() {
      if (this.priceSetting.details.length >= 50) {
        this.$alert("最多只能添加50条", "提示", {
          confirmButtonText: "确定",
        });
      } else {
        //   先判断前面输入的数据是否有交叉，没有交叉再新增一条
        let isCross = this.checkIsCross("price");
        if (!isCross) {
          this.priceSetting.details.push({
            start: "0",
            end: "",
            value: "无货",
            type: "original",
          });
        }
      }
    },
    clearStockItem(itemIndex) {
      this.stockSetting.details = this.stockSetting.details.filter(
        (item, index) => itemIndex !== index
      );
    },
    clearPriceItem(itemIndex) {
      this.priceSetting.details = this.priceSetting.details.filter(
        (item, index) => itemIndex !== index
      );
    },
    onDecrease(step) {
      if (Number(this.stockSetting.autoReceiveOrder || 0) > 1) {
        this.stockSetting.autoReceiveOrder =
          Number(this.stockSetting.autoReceiveOrder || 0) - step;
      }
    },
    onIncrease(step) {
      this.stockSetting.autoReceiveOrder =
        Number(this.stockSetting.autoReceiveOrder || 0) + step;
    },
    onNumberInput(type, item) {
      switch (type) {
        case "start":
          if (item.start && Number(item.start || 0) < 0) {
            item.start = 0;
          }
          break;
        case "end":
          if (item.end && Number(item.end || 0) < Number(item.start || 0)) {
            item.end = item.start ? Number(item.start) + 1 : 0;
          }
          break;
        case "value":
          break;
        case "autoReceiveOrder":
          if (Number(this.stockSetting.autoReceiveOrder || 0) < 1) {
            this.stockSetting.autoReceiveOrder = 1;
          }
          if (Number(this.stockSetting.autoReceiveOrder || 0) % 1 !== 0) {
            this.stockSetting.autoReceiveOrder = Math.floor(
              Number(this.stockSetting.autoReceiveOrder || 0)
            );
          }
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.goods_edit {
  height: 100%;
  display: flex;
  flex-direction: column;
  .goods_edit_body {
    flex: 1;
    overflow-y: auto;
    padding: @padding-size-main;
    .goods_div {
      .goods_title {
        font-family: PingFangSC-Medium;
        font-size: 16px;
        color: #333333;
        line-height: 22px;
        font-weight: 500;
      }
      .title {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .dashed {
          height: 1px;
          margin: 16px;
          flex: 1;
          border-bottom: 1px dashed @border-color-base;
        }
      }
      .goods_info {
        padding: @padding-size-secondary;
        /deep/.el-input__suffix {
          line-height: 40px;
        }
        .goods_label {
          color: @text-color-secondary;
        }
        .goods_content {
          color: @text-color-base;
        }
        /deep/ .radio-style {
          margin: 9px 24px 0 0;
          &:last-child {
            margin-left: 0;
          }
          .el-radio__label {
            padding-left: 4px;
          }
        }
      }
      .tip_ptn {
        display: flex;
        font-size: 14px;
        line-height: 30px;
      }
      .tip_ptn input[type="radio"] {
        opacity: 1;
      }
      .tip_stock {
        display: flex;
        align-items: center;
        margin-bottom: 0.5rem;
        .radio-margin {
          margin: 0 @margin-size-main;
        }
        .mt-3 {
          margin-top: 1rem !important;
        }
      }
      .el-radio-group label {
        margin-bottom: 0;
      }
      .tips_logo {
        color: #fa8c16;
        cursor: pointer;
      }
      .del_logo {
        color: #fa8c16;
        cursor: pointer;
        font-size: 18px;
        margin-right: 10px;
      }
      .stock-container {
        // margin: 0 15px 15px 15px;
        padding: 16px;
        text-align: left;
        width: 55rem;
        background: #f5f5f5;
        border-radius: 2px;
        /deep/input::-webkit-outer-spin-button,
        /deep/input::-webkit-inner-spin-button {
          -webkit-appearance: none;
        }
      }
      /deep/.el-input-number .el-input__inner {
        padding-left: 10px;
        padding-right: 10px;
        text-align: left;
      }
      /deep/.el-col {
        margin: 5px 0;
      }
      .flex_box {
        display: flex;
        margin: 16px 0;
        .label {
          width: 50px;
        }
        .img_box {
          display: flex;

          .img_item {
            margin-right: 16px;
            .img_item_box {
              width: 100px;
              height: 100px;
              object-fit: cover;
            }
          }
        }
        /deep/.el-upload-list--picture-card .el-upload-list__item {
          width: 104px;
          height: 104px;
        }
        /deep/.el-upload--picture-card {
          width: 104px;
          height: 104px;
          line-height: 104px;
        }
        /deep/.el-dialog {
          width: 40%;
        }
        .img_title_btn {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 104px;
          margin-top: 8px;
          margin-right: 8px;
          a {
            cursor: pointer;
            text-decoration: underline;
            color: @color-link;
          }
        }
      }
    }
  }
  .goods_edit_footer {
    padding: 12px 0;
    border-top: 1px solid @border-color-base;
    text-align: center;
  }
}
</style>
